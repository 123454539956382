<template>
  <v-container class="pa-16">
    <h4 class="text-h4">404</h4>
    <div class="text-h6">Такая страница не найдена...</div>
  </v-container>
</template>

<script>
export default {
  name: "404Page",
};
</script>

<style></style>
